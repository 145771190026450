import { Drawer, Dropdown } from 'antd';
import cx from 'classnames';
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import CONFIG from 'config';
import useMineRoleContext from 'hooks/useMineRole';
import { ActionPermission } from 'constants/common.constant';

import imagesAdmin from 'assets/images/admin/index';
import images from 'assets/images/common';
import { ReactComponent as IcCompanies } from 'assets/images/common/icCompanies.svg';
import { ReactComponent as IcVerifications } from 'assets/images/common/icVerifications.svg';
import { ReactComponent as IcProfile } from 'assets/images/common/ic_profile.svg';
import NavLinkItem from 'components/old-version/nav/NavLinkItem';
import ROUTES from 'constants/routes.constant';
import { doLogoutAction, logoutAction } from 'store/auth/auth.action';
import { resetReducer } from 'store/companies/companies.action';

import withAuth from '../../hocs/withAuth';
import classes from './main-layout.module.scss';

import './drawer.scss';
import { isProduction } from '../../helpers/common.helper';

const MainLayout: FC = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleProfile, setVisibleProfile] = useState<boolean>(false);
  const [visibleProfileMb, setVisibleProfileMb] = useState<boolean>(false);
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const { checkRoleIsBlock } = useMineRoleContext();
  useEffect(() => {
    if (!location?.pathname?.includes(ROUTES.MAIN.COMPANIES)) {
      dispatch(resetReducer());
    }
  }, [dispatch, location?.pathname]);
  const profileContent = () => {
    return (
      <div className={classes.profileContent}>
        <NavLink
          to="/profile"
          className={classes.content}
          activeClassName={cx(classes.content, classes.active)}
          onClick={() => setVisibleProfile(false)}
        >
          <img src={images.icProfile} alt="icon" /> Profile
        </NavLink>
        <button
          type="button"
          className={classes.content}
          onClick={() => {
            dispatch(logoutAction.request());
          }}
        >
          <img src={images.icLogout} alt="setting icon" />
          Log Out
        </button>
      </div>
    );
  };
  const menuContent = () => {
    return (
      <div className={classes.menuContent}>
        {!checkRoleIsBlock(
          `${ROUTES.MAIN.ROLE_PERMISSION}`,
          ActionPermission.VIEW,
        ) && (
          <NavLink
            to="/role-permission"
            className={classes.content}
            activeClassName={cx(classes.content, classes.active)}
            onClick={() => setVisible(false)}
          >
            Role & Permission Management
          </NavLink>
        )}
        {!checkRoleIsBlock(
          `${ROUTES.MAIN.INTERNAL_USER}`,
          ActionPermission.VIEW,
        ) && (
          <NavLink
            to="/internal-user"
            className={classes.content}
            activeClassName={cx(classes.content, classes.active)}
            onClick={() => setVisible(false)}
          >
            Internal User Management
          </NavLink>
        )}
        {!checkRoleIsBlock(
          `${ROUTES.MAIN.SETTINGS}`,
          ActionPermission.VIEW,
        ) && (
          <NavLink
            to="/settings"
            className={classes.content}
            activeClassName={cx(classes.content, classes.active)}
            onClick={() => setVisible(false)}
          >
            Settings
          </NavLink>
        )}
      </div>
    );
  };

  return (
    <div
      id="app-container"
      className={classes.appContainer}
      onScroll={() => {
        setVisible(false);
        setVisibleProfile(false);
      }}
    >
      <div className={classes.header}>
        <div className={classes.navigationSection}>
          <img
            className={classes.logoAdmin}
            src={imagesAdmin.IcLogoGray}
            alt="logo"
          />
          <div className={classes.separator} />
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.VERIFICATION}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcVerifications />}
              label={'Dashboard'}
              isHighLighted={location.pathname.includes(
                ROUTES.MAIN.VERIFICATION,
              )}
              url={ROUTES.MAIN.VERIFICATION}
            />
          )}
          {(!checkRoleIsBlock(
            `${ROUTES.MAIN.NETWORK}/credential`,
            ActionPermission.VIEW,
          ) ||
            !checkRoleIsBlock(
              `${ROUTES.MAIN.NETWORK}/verify-request`,
              ActionPermission.VIEW,
            )) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcCompanies />}
              label={'Network'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.NETWORK)}
              url={
                !checkRoleIsBlock(
                  `${ROUTES.MAIN.NETWORK}/credential`,
                  ActionPermission.VIEW,
                )
                  ? `${ROUTES.MAIN.NETWORK}/credential`
                  : `${ROUTES.MAIN.NETWORK}/verify-request`
              }
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.COMPANIES}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcCompanies />}
              label={'Companies'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.COMPANIES)}
              url={ROUTES.MAIN.COMPANIES}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.CREDENTIAL}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcCompanies />}
              label={'Issue'}
              isHighLighted={
                location.pathname.includes(ROUTES.MAIN.CREDENTIAL) &&
                !location.pathname.includes(ROUTES.MAIN.NETWORK)
              }
              url={ROUTES.MAIN.CREDENTIAL}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.VERIFY}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcCompanies />}
              label={'Verify'}
              isHighLighted={
                location.pathname.includes(ROUTES.MAIN.VERIFY) &&
                !location.pathname.includes(
                  `${ROUTES.MAIN.NETWORK}/verify-request`,
                )
              }
              url={ROUTES.MAIN.VERIFY}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.MARKETPLACE}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcCompanies />}
              label={'Marketplace'}
              isHighLighted={location.pathname.includes(
                ROUTES.MAIN.MARKETPLACE,
              )}
              url={ROUTES.MAIN.MARKETPLACE}
            />
          )}

          {!checkRoleIsBlock(
            `${ROUTES.MAIN.MESSAGE}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLink}
              icon={<IcCompanies />}
              label={'Message'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.MESSAGE)}
              url={ROUTES.MAIN.MESSAGE}
            />
          )}
        </div>
        <div className={classes.customProfile}>
          <div className={classes.optionProfile}>
            <div className={classes.body}>
              <>
                {!checkRoleIsBlock(
                  `${ROUTES.MAIN.ROLE_PERMISSION}`,
                  ActionPermission.VIEW,
                ) ||
                !checkRoleIsBlock(
                  `${ROUTES.MAIN.INTERNAL_USER}`,
                  ActionPermission.VIEW,
                ) ||
                !checkRoleIsBlock(
                  `${ROUTES.MAIN.SETTINGS}`,
                  ActionPermission.VIEW,
                ) ? (
                  <Dropdown
                    placement="bottomLeft"
                    trigger={['click']}
                    onVisibleChange={(value: any) => {
                      setVisible(value);
                    }}
                    overlay={menuContent}
                    overlayClassName="custom-dropdown-dashboard"
                    visible={visible}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <img src={images.icTabMain} alt="icon_tab_main" />
                  </Dropdown>
                ) : (
                  <></>
                )}
              </>
              <Dropdown
                placement="bottomLeft"
                trigger={['click']}
                onVisibleChange={(value: any) => {
                  setVisibleProfile(value);
                }}
                overlay={profileContent}
                overlayClassName="custom-dropdown-dashboard"
                visible={visibleProfile}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <img
                  src={images.icProfile}
                  alt="icon_tab_main"
                  width={28}
                  height={28}
                />
              </Dropdown>
            </div>
          </div>
          <div className={classes.optionMobile}>
            <button
              type="button"
              className={classes.iconMobile}
              onClick={() => setVisibleMenu(true)}
            >
              <img src={images.icMobileNav} alt="icon" />
            </button>
            <Dropdown
              placement="bottomLeft"
              trigger={['click']}
              onVisibleChange={(value: any) => {
                setVisibleProfileMb(value);
              }}
              overlay={profileContent}
              overlayClassName="custom-dropdown-dashboard"
              visible={visibleProfileMb}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <img
                src={images.icProfile}
                alt="icon_tab_main"
                width={28}
                height={28}
              />
            </Dropdown>
          </div>
        </div>
      </div>

      <div className={classes.container}>{children}</div>
      <Drawer
        width="68%"
        placement="left"
        closable={false}
        bodyStyle={{ padding: '27px 11px 0 14px' }}
        className={cx(classes.customDrawer, 'nav-drawer-customer')}
        onClose={() => setVisibleMenu(false)}
        visible={visibleMenu}
        key="left"
      >
        <div className={cx(classes.body, ' d-flex flex-column h-100')}>
          <div className={classes.logo}>
            <img
              className={classes.logoAdmin}
              src={imagesAdmin.IcLogoGray}
              alt="logo"
            />
          </div>
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.VERIFICATION}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcVerifications />}
              label={'Dashboard'}
              isHighLighted={location.pathname.includes(
                ROUTES.MAIN.VERIFICATION,
              )}
              url={ROUTES.MAIN.VERIFICATION}
              onClick={() => setVisibleMenu(false)}
            />
          )}
          {(!checkRoleIsBlock(
            `${ROUTES.MAIN.NETWORK}/credential`,
            ActionPermission.VIEW,
          ) ||
            !checkRoleIsBlock(
              `${ROUTES.MAIN.NETWORK}/verify-request`,
              ActionPermission.VIEW,
            )) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Network'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.NETWORK)}
              url={
                !checkRoleIsBlock(
                  `${ROUTES.MAIN.NETWORK}/credential`,
                  ActionPermission.VIEW,
                )
                  ? `${ROUTES.MAIN.NETWORK}/credential`
                  : `${ROUTES.MAIN.NETWORK}/verify-request`
              }
              onClick={() => setVisibleMenu(false)}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.COMPANIES}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Companies'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.COMPANIES)}
              url={ROUTES.MAIN.COMPANIES}
              onClick={() => setVisibleMenu(false)}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.CREDENTIAL}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Issue'}
              isHighLighted={
                location.pathname.includes(ROUTES.MAIN.CREDENTIAL) &&
                !location.pathname.includes(ROUTES.MAIN.NETWORK)
              }
              url={ROUTES.MAIN.CREDENTIAL}
              onClick={() => setVisibleMenu(false)}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.VERIFY}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Verify'}
              isHighLighted={
                location.pathname.includes(ROUTES.MAIN.VERIFY) &&
                !location.pathname.includes(
                  `${ROUTES.MAIN.NETWORK}/verify-request`,
                )
              }
              url={ROUTES.MAIN.VERIFY}
              onClick={() => setVisibleMenu(false)}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.MARKETPLACE}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Marketplace'}
              isHighLighted={location.pathname.includes(
                ROUTES.MAIN.MARKETPLACE,
              )}
              url={ROUTES.MAIN.MARKETPLACE}
              onClick={() => setVisibleMenu(false)}
            />
          )}

          {!checkRoleIsBlock(
            `${ROUTES.MAIN.MESSAGE}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Message'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.MESSAGE)}
              url={ROUTES.MAIN.MESSAGE}
              onClick={() => setVisibleMenu(false)}
            />
          )}
          {!checkRoleIsBlock(
            `${ROUTES.MAIN.SETTINGS}`,
            ActionPermission.VIEW,
          ) && (
            <NavLinkItem
              className={classes.navLinkOnDrawer}
              icon={<IcCompanies />}
              label={'Settings'}
              isHighLighted={location.pathname.includes(ROUTES.MAIN.SETTINGS)}
              url={ROUTES.MAIN.SETTINGS}
              onClick={() => setVisibleMenu(false)}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default withAuth(MainLayout, {
  needAuth: true,
  unMatchingRedirect: ROUTES.AUTH.LOGIN,
});
